<template>
  <v-card class="w-full mx-2 p-1 flex flex-row" v-on:click="() => $emit('click')">
    <div
      class="
        bg-center bg-cover bg-no-repeat
        h-16
        w-16
        rounded-lg
        m-auto
        relative
      "
      :class="{ 'inactive-user-image': !value.isEmployed }"
      :style="{
        'background-image': `url('${
          value.profileImageUrl || missingProfileImage
        }')`,
        'background-color': !value.profileImageUrl ? '#3b938d' : 'initial',
      }"
    ></div>
    <div class="my-2 px-2 flex-1">
      <div class="text-lg">{{ value.firstName }} {{ value.lastName }}</div>
      <div v-if="value.jobTitle" class="text-md text-gray-500">{{ value.jobTitle }}</div>
    </div>
  </v-card>
</template>
<script>
import missingProfileImage from "../../assets/images/missing_profile.svg";

export default {
  name: "UserQueryCard",
  props: {
    value: Object,
  },
  data: () => ({
    missingProfileImage,
  }),
};
</script>